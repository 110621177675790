import { Row, Col, Form, Modal } from "react-bootstrap";
import Chats from "./Chats";
import ChatRooms from "./ChatRooms";
import {
  getAllRoomAPI,
  getAllUsersList,
  createRoomAPI,
  roomDeative,
  getRoomUserList,
} from "../apis/UserApis";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { WelcomeScreen } from "./WelcomeScreen";
import NavigationSide from "./Navigationscreen";
import ManageUserScreen from "./ManageUserScreen";
import ManageChatroomScreen from "./ManageChatRoom";
import { v4 as uuidv4 } from "uuid";

const toastStyle = {
  position: "top-right",
  theme: "colored",
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #74c9a9" : "2px solid #74c9a9",
    border: "2px solid #74c9a9",
    borderRadius: "6px",
    padding: "10px",
    marginBottom: "10px",
    width: "100%",
  }),
};
const DashboradForAdminScreen = ({
  handleJoinRoom,
  socket,
  userDetails,
  joinRoom,
  logout,
}) => {
  const [roomList, setRoomList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [roomName, setRoomName] = useState("");
  const [roomId, setRoomId] = useState("");
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState("");
  const [userList, setUserList] = useState();
  const [userName, setUserName] = useState();
  const [roomPrimaryId, setRoomPrimaryId] = useState();
  const [roomUserListForAdd, setRoomUserListForAdd] = useState([]);
  const [roomListForAdd, setRoomListForAdd] = useState([]);
  const [isChatShow, setIsChatShow] = useState(false);
  const [roomDetails, setRoomDetails] = useState();
  const [color, setColor] = useState();
  const [selectedComponent, setSelectedComponent] = useState("dashboard");
  const handleClose = () => setShow(false);
  useEffect(() => {
    getAllRoomList();
    getAllUsersList("all").then((data) => {
      setUserList(data);
    });
  }, []);

  const handleShowForCreateRoom = () => {
    setShow(true);
    setShowType("createRoom");
  };

  const handleShowForAssignUser = (type) => {
    if (type == "assignUserForSelectRoom") {
      handleChangeRoom(roomId);
      setRoomId(roomId);
    }
    setShow(true);
    setShowType(type);
  };

  const handleRefresh = (item, color) => {
    setColor(color);
    setIsChatShow(true);
    setRoomDetails(item);
    setRoomPrimaryId(item?.id);
    setRoomName(item?.room_name);
    setRoomId(item?.room_id);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const getAllRoomList = () => {
    getAllRoomAPI("all").then((res) => {
      // setRoomId(res[0]?.room_id);
      // setRoomName(res[0]?.room_name);
      setRoomList(res);
      let result = res
        ?.filter((item) => item?.is_active == true)
        .map((v) => {
          return {
            value: v.room_id,
            label: v.room_name,
          };
        });
      setRoomListForAdd(result);
    });
  };

  const handleCreateRoom = () => {
    if (roomId !== "" && roomName) {
      joinRoom(roomId);
      if (socket?.id !== "") {
        let data = {
          room_id: roomId,
          room_name: roomName,
          userId: userDetails?.id,
        };
        createRoom(data);
      }
      handleClose();
    } else {
      toast.error("Plase Enter Room Name", toastStyle);
    }
    setRoomId("");
    setRoomName("");
    setUserName("");
  };

  const handleAddUserInRoom = () => {
    if (showType == "assignUserForSelectRoom" && !userName) {
      toast.error("Plase select Users", toastStyle);
    } else if (!roomId) {
      toast.error("Plase select Room", toastStyle);
    } else if (!userName) {
      toast.error("Plase select Users", toastStyle);
    }
    if (roomId && userName) {
      joinRoom(roomId);
      if (socket?.id !== "") {
        let data = {
          room_id: roomId,
          userId: "",
        };
        if (userName?.length > 0) {
          userName?.map((item) => {
            data.userId = item?.value;
            createRoom(data);
          });
        }
      }
      handleClose();
    }
  };
  const createRoom = (data) => {
    createRoomAPI(data).then(() => {
      getAllRoomList();
      if (showType == "assignUserForSelectRoom") {
        setUserName("");
      } else {
        setRoomId("");
        setUserName("");
      }
    });
  };
  const handleDeactiveRooms = (is_active) => {
    if (roomPrimaryId) {
      roomDeative(roomPrimaryId, { is_active: is_active }).then(() => {
        getAllRoomList();
      });
    }
  };

  const handleChangeRoom = (e) => {
    getRoomUserList(e).then((res) => {
      let filterUserList = userList.filter(
        (users) =>
          !res?.data?.data.some((roomUser) => roomUser.user_id === users.id)
      );
      let result = filterUserList?.map((v) => {
        return {
          value: v.id,
          label: v.username,
        };
      });
      setRoomUserListForAdd(result);
    });
  };
  const DashboardDeatils = () => {
    return (
      <>
        <div className="dashboard-view">
          <Col sm={4} className="chatroomPadding">
            <ChatRooms
              roomListForUser={roomList}
              handleJoinRoom={handleJoinRoom}
              handleRefresh={handleRefresh}
              userDetails={userDetails}
              roomPrimaryId={roomPrimaryId}
              socket={socket}
            />
          </Col>
          <Col sm={8} className="chatPadding">
            {isChatShow == true ? (
              <Chats
                socket={socket}
                userDetails={userDetails}
                room={roomId}
                // || roomList[0]?.rooms?.room_id || room roomList[0]?.rooms?.room_name
                roomName={roomName}
                key={refreshKey}
                roomList={roomList}
                handleDeactiveRooms={handleDeactiveRooms}
                addUser={handleShowForAssignUser}
                roomPrimaryId={roomPrimaryId}
                roomDetails={roomDetails}
                color={color}
              />
            ) : (
              <WelcomeScreen />
            )}
          </Col>
        </div>
      </>
    );
  };
  const getMenus = () => {
    switch (selectedComponent) {
      case "user-screen":
        return <ManageUserScreen userDetails={userDetails} />;
      case "dashboard":
        return DashboardDeatils();
      case "chatroom":
        return (
          <ManageChatroomScreen
            roomList={roomList}
            userDetails={userDetails}
            roomId={roomId}
            roomPrimaryId={roomPrimaryId}
          />
        );
      default:
        return DashboardDeatils;
    }
  };

  return (
    <>
      <Row className="m-0" style={{ height: "100vh" }}>
        <Col sm={2} className="navbarContainer">
          <NavigationSide
            userDetails={userDetails}
            setSelectedComponent={setSelectedComponent}
            logout={logout}
            handleShowForCreateRoom={handleShowForCreateRoom}
            handleShowForAssignUser={handleShowForAssignUser}
          />
        </Col>
        <Col sm={10}>{getMenus()}</Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ padding: "50px" }}>
          {showType === "createRoom" ? (
            <>
              <Form>
                <Form.Control
                  type="text"
                  onChange={(event) => {
                    setRoomId(uuidv4());
                    setRoomName(event.target.value);
                  }}
                  className="formInputstyle"
                  placeholder="Enter Room Name"
                />
              </Form>
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="buttonstyle"
                  onClick={() => {
                    handleCreateRoom();
                  }}
                >
                  Create Room
                </button>
              </div>
            </>
          ) : null}
          {showType === "assignUser" ||
          showType === "assignUserForSelectRoom" ? (
            <>
              <Form>
                {showType == "assignUser" ? (
                  <Select
                    styles={customStyles}
                    options={roomListForAdd}
                    onChange={(e) => {
                      handleChangeRoom(e?.value);
                      setRoomId(e?.value);
                    }}
                  />
                ) : null}
                <Select
                  isMulti
                  styles={customStyles}
                  options={roomUserListForAdd}
                  onChange={(e) => {
                    setUserName(e);
                  }}
                />
              </Form>
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="buttonstyle"
                  onClick={() => {
                    handleAddUserInRoom();
                  }}
                >
                  Add User
                </button>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default DashboradForAdminScreen;
