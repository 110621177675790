import { Row, Col } from "react-bootstrap";

export const ShowNotificationComponent = ({
  notificaLength,
  notificationData,
}) => {
  return (
    <>
      {notificationData && notificaLength !== 0 ? (
        <div>
          {notificationData && (
            <Row>
              <Col sm={8}>
                <span className="notifi-title">
                  {notificationData?.message}
                </span>
              </Col>
              <Col
                sm={4}
                className="d-flex justify-content-end"
                style={{ marginTop: "-30px" }}
              >
                <div>
                  <Row>
                    <span className="notifi-title">
                      {notificationData.created}
                    </span>
                  </Row>

                  <Row className="d-flex justify-content-md-center">
                    {notificaLength ? (
                      <div className="notification">{notificaLength}</div>
                    ) : (
                      ""
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
