import io from "socket.io-client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getAllUsersList } from "../apis/UserApis";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboradForUserScreen from "./dashboardscreenForUser";
import DashboradForAdminScreen from "./DashboardScreenForAdmin";

// const socket = io.connect("http://localhost:3001");
const socket = io.connect("https://testchatbe.karmaalab.com/");

const Dashboard = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    const userData = sessionStorage.getItem("userDetails");
    const userDetail = JSON.parse(userData);
    getAllUsersList(userDetail?.id).then((data) => {
      setUserDetails(data);
    });
  }, []);

  const joinRoom = async (id) => {
    if (id !== "") {
      socket.emit("join_room", id);
    }
  };

  const handleLogout = () => {
    navigate("/");
    sessionStorage.clear();
  };

  const handleJoinRoom = (item) => {
    if (userDetails?.roles?.label === "admin") {
      joinRoom(item?.room_id);
    } else {
      joinRoom(item?.rooms?.room_id);
    }
  };

  return (
    <>
      {userDetails?.roles?.label === "admin" ? (
        <>
          <DashboradForAdminScreen
            handleJoinRoom={handleJoinRoom}
            logout={handleLogout}
            socket={socket}
            userDetails={userDetails}
            joinRoom={joinRoom}
          />
        </>
      ) : (
        <div>
          <DashboradForUserScreen
            handleJoinRoom={handleJoinRoom}
            logout={handleLogout}
            socket={socket}
            userDetails={userDetails}
          />
        </div>
      )}

      <ToastContainer />
    </>
  );
};

export default Dashboard;
