import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Container,
  Form,
  Row,
  Modal,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { getAllChatRooms } from "../apis/UserApis";
import ShowMembersComponent from "./ShowMembersScreen";

const ManageChatroomScreen = ({
  userDetails,

  roomPrimaryId,
}) => {
  const [chatData, setChatData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [roomId, setRoomId] = useState();
  useEffect(() => {
    getAllChatRooms("all").then((item) => {
      const roomMap = item.reduce((acc, item) => {
        if (!acc[item.room_id]) {
          acc[item.room_id] = {
            id: item.id,
            room_id: item.room_id,
            room_name: item.room_name,
            is_active: item.is_active,
            users: new Set(),
          };
        }
        acc[item.room_id].users.add(item.username);
        return acc;
      }, {});

      let result = Object.values(roomMap).map((room) => ({
        ...room,
        users: Array.from(room.users).join(", "),
      }));
      mappedChatroomData(result);
    });
  }, []);

  const mappedChatroomData = (mapData) => {
    let data = mapData?.map((item, index) => {
      const userList = item?.users?.split(",").map((user) => user.trim());
      return {
        id: index + 1,
        roomId: item?.room_id,
        roomName: item?.room_name,
        status: item?.is_active == true ? "Active" : "Deactive",
        users: item?.users == "" ? "No" : userList?.length,
      };
    });
    setChatData(data);
  };

  const filteredData = chatData.filter((item) =>
    searchTerm === ""
      ? true
      : item.roomName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (room) => {
    setShow(true);
    setRoomId(room);
  };

  const ChatRoomColumns = [
    {
      name:<span className="fw-bold">SR NO</span>,
      selector: (row) => <span className="fw-bold">{row.id}</span>,
      sortable: true,
      width: "15%",
      center: true,
    },
    {
      name:<span className="fw-bold">ROOM ID</span>,
      cell: (row) => (
        <>
          <OverlayTrigger
            placement="bottom"
            overlay={(triggerProps) => (
              <Tooltip id="button-tooltip-2" {...triggerProps}>
                {row.roomId}
              </Tooltip>
            )}
          >
            <span className="roomColumn">{row.roomId}</span>
          </OverlayTrigger>
        </>
      ),
      sortable: true,
      width: "25%",
    },
    {
      name: <span className="fw-bold">ROOM NAME</span>,
      selector: (row) => row.roomName,
      sortable: true,
      width: "20%",
    },
    {
      name:<span className="fw-bold">MEMBERS</span>,
      selector: (row) => row.users,
      sortable: true,
      width: "20%",
      cell: (row) => (
        <Button
          style={{
            backgroundColor: "#38767C",
            padding: "0 10px",
            borderRadius: "15px",
          }}
          onClick={() => {
            handleShow(row?.roomId);
          }}
        >
          {row?.users}
        </Button>
      ),
    },
    {
      name:<span className="fw-bold">STATUS</span>,
      selector: (row) => row.status,
      sortable: true,
      width: "20%",
      cell: (row) => (
        <span
          className={row?.status == "Active" ? "chatactive" : "chatdeactive"}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const tableCustomStyles = {
    striped: {
      backgroundColor: "#eef5f3e6 !important", 
    },
    cells: {
      style: {
        textAlign: "center",
      },
    },
  };
  createTheme("custom", {
    background: {
      default: "#eef5f3e6", 
    },
    striped: {
      default: "white", 
    },
  });
  return (
    <Container>
      <div className="pt-2">
        <h2 className="fw pt-3 m-2" style={{ fontFamily: "Inter, sans-serif" }}>
          Manage Chatrooms
        </h2>
        <Card style={{ backgroundColor: "#eef5f3e6" }}>
          <Card.Body className="p-0">
            <Row
              className="m-0"
              style={{
                padding: "20px 0 0 0",
              }}
            >
              <Col sm={4} className="d-flex justify-content-center m-0">
                <Form.Control
                  type="search"
                  className="m-1"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Col>
            </Row>
            <DataTable
              columns={ChatRoomColumns}
              data={filteredData}
              pagination
              responsive
              highlightOnHover
              striped
              customStyles={tableCustomStyles}
              theme="custom"
            />
          </Card.Body>
        </Card>
      </div>
      <div className="modal-body shadow-lg">
        <Modal
          show={show}
          onHide={handleClose}
          className=" p-3 custom-modal-dialog " 
        >
          <ShowMembersComponent
            userDetails={userDetails}
            roomPrimaryId={roomPrimaryId}
            room={roomId}
          />
        </Modal>
      </div>
    </Container>
  );
};
export default ManageChatroomScreen;
