import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginAPI } from "../apis/UserApis";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { Button, Col, Form, Row } from "react-bootstrap";

const Login = () => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "" });

  const toastOptions = {
    position: "bottom-right",
    autoClose: 8000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (sessionStorage.getItem(parseInt("userDetails"))) {
      navigate("/");
    }
  }, []);

  const handleChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    const { email, password } = loginDetails;
    if (email === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    } else if (password === "") {
      toast.error("Email is required.", toastOptions);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { email, password } = loginDetails;

      LoginAPI({ email, password }).then((res) => {
        if (res.status == 200) {
          if (typeof res?.data?.data !== "string") {
            navigate("/dashboard");
          }
          // else {
          //   toast.error(res?.data?.data, toastOptions);
          // }
        } else {
          toast.error(res.message, toastOptions);
        }
      });
    }
  };

  return (
    <>
      <FormContainer>
        <div className="logincontainer">
          <Form onSubmit={(event) => handleSubmit(event)}>
            <div className="brand">
              <IoChatbubbleEllipsesSharp
                color="#026873"
                style={{ height: "50px", width: "60px" }}
              />
              <h1 className="fw-bold">Chat Application</h1>
            </div>
            <Form.Control
              type="text"
              placeholder="Email"
              name="email"
              onChange={(e) => handleChange(e)}
              min="3"
            />
            <div>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                onChange={(e) => handleChange(e)}
                className="mb-3"
              />
              <span>
                Don’t have an account? <Link to="/register">Create One.</Link>
              </span>
            </div>
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Button type="submit" style={{backgroundColor:"#026873"}}>Log In</Button>
              </Col>
            </Row>
          </Form>
        </div>
        <ToastContainer />
      </FormContainer>
    </>
  );
};
export default Login;
const FormContainer = styled.div`
  background-color: #eef5f3;
  display: flex;
  height: 100vh;
  justify-content: center;
  .brand {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: #000000;
      text-transform: uppercase;
    }
  }
  .logincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 3rem;
    gap: 2rem;
    box-shadow: 0 4px 4px 4px #49787d4d;
  }
  input {
    background-color: transparent;
    padding: 1rem;
    border: 0.1rem solid #74c9a9;
    border-radius: 0.8rem;
    color: #000000;
    width: 100%;
    font-size: 1rem;
    &:focus {
      border: 0.1rem solid #74c9a9;
      outline: none;
    }
  }
  Button {
    background-color: #026873;
    color: white;
    padding: 1rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2rem;
    font-size: 1rem;
    &:hover {
      background-color: #026873;
    }
    &:focus {
      background-color: #026873;
    }
  }
  span {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
    padding-top: 10px;
    a {
      color: #04bf8a;
      text-decoration: none;
    }
  }
`;
