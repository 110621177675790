import axios from "axios";
import {
  allUsersRoute,
  createRoomRoute,
  loginRoute,
  getAllRoles,
  registerRoute,
  addMessage,
  getMessage,
  getRooms,
  updateUserRoute,
  deleteUser,
  deactiveRoom,
  searchRoom,
  getUserInRooms,
  removeUserRoom,
  searchMembers,
  allUsersByRoom,
  getNotification,
  addNotification,
  removeNotifi,
  getRoomChat,
  getUsersRooms,
} from "../utils/APIRoutes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastStyle = {
  position: "top-right",
  theme: "colored",
};
export const getAllUsersList = async (id) => {
  let result = await axios.get(`${allUsersRoute}/${id}`);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message || result?.message, toastStyle);
  }
};

export const createRoomAPI = async (data) => {
  let result = await axios.post(createRoomRoute, data);
  if (result?.status == 200) {
    if (typeof result?.data?.data === "string") {
      toast.error(result?.data?.data, toastStyle);
    } else if (typeof result?.data?.data !== "string") {
      toast.success(result?.data?.message, toastStyle);
    }
    return result?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const LoginAPI = async (data) => {
  try {
    let result = await axios.post(loginRoute, data, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer YOUR_ACCESS_TOKEN",
      },
    });
    if (result?.status == 200) {
      if (typeof result?.data?.data !== "string") {
        toast.success(result?.data?.message, toastStyle);
        sessionStorage.setItem(
          "userDetails",
          JSON.stringify(result?.data?.data)
        );
      } else if (typeof result?.data?.data == "string") {
        toast.error(result?.data?.data, toastStyle);
      }

      return result;
    } else {
      toast.error(result?.message, toastStyle);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    // alert("An error occurred while fetching data.");
    throw error;
  }
};

export const getAllRolesAPI = async () => {
  let result = await axios.get(`${getAllRoles}/all`);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const userRegistration = async (data) => {
  let result = await axios.post(registerRoute, data);
  if (result?.status === 200) {
    if (typeof result?.data?.data === "string") {
      toast.error(result?.data?.data, toastStyle);
    } else if (typeof result?.data?.data !== "string") {
      toast.success(result?.data?.message, toastStyle);
      return result;
    }
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const addMessageAPI = async (data) => {
  let result = await axios.post(addMessage, data);
  if (result?.status == 200) {
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getAllMessageAPI = async (id, data) => {
  let result = await axios.post(`${getMessage}/${id}`, data);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getAllRoomAPI = async (id) => {
  let result = await axios.get(`${getRooms}/${id}`);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const updateUserAPI = async (id, data) => {
  let result = await axios.put(`${updateUserRoute}/${id}`, data);
  if (result?.status == 200) {
    toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const deleteUserAPI = async (id) => {
  let result = await axios.delete(`${deleteUser}/${id}`);
  if (result?.status == 200) {
    toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const roomDeative = async (id,data) => {
  let result = await axios.put(`${deactiveRoom}/${id}`,data);
  if (result?.status == 200) {
    toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const searchRoomList = async (search, userId, role) => {
  let urlParam = search ? `?name=${search}` : "";

  let result = await axios.post(`${searchRoom}/${userId}${urlParam}`, {
    role: role,
  });
  if (result?.status == 200) {
    // toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getRoomUserList = async (id) => {
  let result = await axios.get(`${getUserInRooms}/${id}`);
  if (result?.status == 200) {
    // toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const removeUserFromRoom = async (id, room_id) => {
  let result = await axios.delete(`${removeUserRoom}/${id}/${room_id}`);
  if (result?.status == 200) {
    toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const searchMembersList = async (search, room_id) => {
  let urlParam = search ? `?name=${search}` : "";

  let result = await axios.get(`${searchMembers}/${room_id}${urlParam}`);
  if (result?.status == 200) {
    // toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const addNotificationAPI = async (data) => {
  let result = await axios.post(addNotification, data);
  if (result?.status == 200) {
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getAllNotificationAPI = async (id, data) => {
  let result = await axios.post(`${getNotification}/${id}`, data);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const removeNotification = async (user_id, room_id) => {
  let result = await axios.delete(`${removeNotifi}/${user_id}/${room_id}`);
  if (result?.status == 200) {
    // toast.success(result?.data?.message, toastStyle);
    return result;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getAllChatRooms = async (id) => {
  let result = await axios.get(`${allUsersByRoom}/${id}`);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};

export const getAllUserWithRooms = async (id) => {
  let result = await axios.get(`${getUsersRooms}/${id}`);
  if (result?.status == 200) {
    return result?.data?.data;
  } else {
    toast.error(result?.message, toastStyle);
  }
};
