import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import {
  addMessageAPI,
  getAllMessageAPI,
  addNotificationAPI,
} from "../apis/UserApis";
import { Row, Col, Form, InputGroup, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { FaRegFaceSmile } from "react-icons/fa6";
import { CiMenuKebab } from "react-icons/ci";
import { IoNavigateCircle } from "react-icons/io5";
import ShowMembersComponent from "./ShowMembersScreen";
import moment from "moment-timezone";
import { IoArrowBackSharp } from "react-icons/io5";
import { IoPersonCircleOutline } from "react-icons/io5";

const Chats = ({
  socket,
  userDetails,
  room,
  roomName,
  handleDeactiveRooms,
  addUser,
  roomPrimaryId,
  roomDetails,
  color,
}) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [groupInfo, setGroupInfo] = useState(false);

  const sendMessage = async () => {
    if (currentMessage !== "" && room !== "") {
      const messageData = {
        room: room,
        author: userDetails?.username,
        message: currentMessage,
        sender: userDetails?.id,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };
      await socket.emit("send_message", messageData);
      await socket.emit("send-notification", messageData);
      const data = {
        message: currentMessage,
        sender: userDetails?.id,
        room_id: room,
        date: new Date(Date.now()),
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };
      const notificationdata = {
        message: currentMessage,
        sender_id: userDetails?.id,
        room_id: room,
        is_show: true,
        created:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
        roomPrimaryId: roomPrimaryId,
        receiver_id: "",
      };
      addMessageAPI(data);
      addNotificationAPI(notificationdata);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    if (room !== "") {
      getAllMessageAPI("all", {
        room_id: room,
      }).then((res) => {
        const messageData = [...res, ...messageList];
        setMessageList(messageData);
      });

      const messageHandler = (data) => {
        setMessageList((list) => [...list, data]);
      };

      socket.on("receive_message", messageHandler);

      return () => {
        socket.off("receive_message", messageHandler);
      };
    }
  }, []);

  const handleShowGroupInfo = (event) => {
    setGroupInfo(event);
  };
  let prevSender = "";

  return (
    <FormContainer>
      <div className="chat-window">
        {groupInfo == true ? (
          <>
            <Row
              style={{
                boxShadow: "0 4px 4px 0 #00000047",
                backgroundColor: "#ffffff",
                margin: "1px",
              }}
            >
              <Col sm={2} className="d-flex justify-content-center pt-2">
                <IoArrowBackSharp
                  size={30}
                  onClick={() => {
                    handleShowGroupInfo(false);
                  }}
                />
              </Col>
              <Col
                sm={7}
                className="d-flex p-3"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <IoPersonCircleOutline size={100} color={color} />
                <h3 style={{ fontFamily: "sans-serif" }}>{roomName}</h3>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-4">
              <Col sm={6} className="box-shadow-for-show">
                <ShowMembersComponent
                  userDetails={userDetails}
                  roomPrimaryId={roomPrimaryId}
                  room={room}
                />
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        {groupInfo == false ? (
          <>
            <div className="container-chat">
              <div className="chat-header">
                <Row className="m-0">
                  <Col sm={10}>
                    <Row>
                      <Col
                        sm={2}
                        className="d-flex justify-content-center headingChat"
                      >
                        <IoPersonCircleOutline
                          size={55}
                          color={color}
                          onClick={() => {
                            handleShowGroupInfo(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Col>
                      <Col
                        sm={10}
                        className="p-0"
                        style={{ cursor: "default" }}
                      >
                        <span className="chatSelectedtitle">{roomName}</span>
                      </Col>
                    </Row>
                  </Col>
                  {userDetails?.roles?.label === "admin" ? (
                    <Col
                      sm={2}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{
                            backgroundColor: "transparent",
                            color: "transparent",
                          }}
                        >
                          <CiMenuKebab size={30} color="#000000" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{ boxShadow: "3px 3px 0px 1px #bbbfc3" }}
                        >
                          {roomDetails?.is_active == true ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  handleDeactiveRooms(false);
                                }}
                                className="dropMenu"
                              >
                                <b> Deactivate Chatroom</b>
                              </Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                onClick={() => {
                                  addUser("assignUserForSelectRoom");
                                }}
                                className="dropMenu"
                              >
                                <b> Add User</b>
                              </Dropdown.Item>
                            </>
                          ) : null}
                          {roomDetails?.is_active == false ? (
                            <Dropdown.Item
                              onClick={() => {
                                handleDeactiveRooms(true);
                              }}
                              className="dropMenu"
                            >
                              <b> Activate Chatroom</b>
                            </Dropdown.Item>
                          ) : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </div>

              <div className="chat-body">
                <ScrollToBottom className="message-container">
                  {messageList.map((messageContent, index) => {
                    let userLocalTime = "";
                    if (messageContent?.time !== "") {
                      const parsedTime = moment(
                        messageContent?.time,
                        "HH:mm:ss"
                      );

                      const userTimeZone =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;

                      userLocalTime = parsedTime
                        .clone()
                        .tz(userTimeZone)
                        .format("hh:mm A");
                    }

                    const showSenderName =
                      messageContent?.sender !== prevSender;
                    prevSender = messageContent?.sender;

                    return (
                      <div
                        className="message"
                        key={index}
                        id={
                          userDetails?.username ===
                          (messageContent?.author ||
                            messageContent?.users?.username)
                            ? "you"
                            : "other"
                        }
                      >
                        <div>
                          <div className="message-content">
                            {showSenderName &&
                            userDetails?.username !==
                              (messageContent?.author ||
                                messageContent?.users?.username) ? (
                              <p
                                style={{
                                  width: "100px",
                                  color: "#70BBF6",
                                }}
                              >
                                {messageContent?.author ||
                                  messageContent?.users?.username}
                              </p>
                            ) : (
                              ""
                            )}
                            <p> {messageContent?.message}</p>
                            <div className="message-meta">
                              <p id="time">{userLocalTime}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ScrollToBottom>
              </div>

              <div className="footer">
                <div>
                  {roomDetails?.is_active == true ? (
                    <div>
                      <Row className="m-0">
                        {/* <Col sm={1} className="d-flex justify-content-end">
                    <IoAddCircle size={50} color="#026873" className="pt-2" />
                  </Col> */}
                        <Col sm={11} style={{ padding: "0 0 0 30px" }}>
                          <InputGroup className="mb-3 p-0">
                            <Form.Control
                              type="text"
                              value={currentMessage}
                              placeholder="Enter Message Here..."
                              onChange={(event) => {
                                setCurrentMessage(event.target.value);
                              }}
                              onKeyPress={(event) => {
                                event.key === "Enter" && sendMessage();
                              }}
                            />
                            <InputGroup.Text id="basic-addon1">
                              <FaRegFaceSmile size={30} color="#A6A6A6" />
                            </InputGroup.Text>
                          </InputGroup>
                        </Col>
                        <Col
                          sm={1}
                          className="d-flex justify-content-start p-0"
                        >
                          <IoNavigateCircle
                            size={50}
                            onClick={sendMessage}
                            color="#026873"
                            className="pt-2"
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <Row className="noMessageContent">
                      <p style={{ textAlign: "center" }}>
                        You can no longer chat in this chatroom
                      </p>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </FormContainer>
  );
};

export default Chats;
const FormContainer = styled.div`
  background-color: #eef5f3;
  box-shadow: 0 4px 4px 5px #a4e2cb80;
  height: 90vh;
  border-radius: 5px;

  // margin: 10px;
  .brand {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    h1 {
      color: #000000;
      text-transform: uppercase;
    }
  }
  .header {
    font-size: 12px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 1px #49787d8a;
    cursor: pointer;
    margin-bottom: 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 3rem;
    box-shadow: 2px 2px 2px 5px #49787d8a;
  }
  input {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0.8rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      border: 0.1rem solid #74c9a9;
      outline: none;
    }
  }
  button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    height: 50px;
    &:hover {
      background-color: transparent;
    }
  }
  span {
    color: #000000;
    a {
      color: #04bf8a;
      text-decoration: none;
      font-weight: bold;
    }
  }
`;
