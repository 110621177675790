import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import {
  searchRoomList,
  getAllNotificationAPI,
  removeNotification,
} from "../apis/UserApis";
import { useEffect, useState } from "react";
import { ShowNotificationComponent } from "./showNotification";
import moment from "moment-timezone";
import { IoPersonCircleOutline } from "react-icons/io5";
import randomColor from "randomcolor";

const ChatRooms = ({
  roomListForUser,
  handleJoinRoom,
  handleRefresh,
  userDetails,
  roomPrimaryId,
  socket,
}) => {
  const [roomList, setRoomList] = useState();
  const [notificationData, setNotificationData] = useState();

  useEffect(() => {
    if (socket) {
      socket.on("receive_notification", (newMessage) => {
        handleRemoveNofi();
      });
    }
    return () => {
      socket.off("receive_notification");
    };
  }, []);

  useEffect(() => {
    handleRemoveNofi();
  }, []);

  const handleSearchChatRoom = (event) => {
    if (event?.length > 3) {
      searchRoomList(event, userDetails?.id, userDetails?.roles?.label).then(
        (res) => {
          setRoomList(res?.data?.data);
        }
      );
    } else {
      setRoomList(roomListForUser);
    }
  };
  const handleRemoveNofi = () => {
    const userData = sessionStorage.getItem("userDetails");
    const userDetail = JSON.parse(userData);
    if (userDetail?.id !== undefined || userDetail?.id !== null) {
      getAllNotificationAPI("all", { user_id: userDetail?.id }).then((res) => {
        setNotificationData(res);
      });
    }
  };
  useEffect(() => {
    setRoomList(roomListForUser);
  }, [roomListForUser]);

  const handleDeleteNotification = (room_id) => {
    removeNotification(userDetails?.id, room_id).then(() => {
      handleRemoveNofi();
    });
  };
  return (
    <>
      <FormContainer>
        <Container>
          <h2
            className="fw pt-2 m-2"
            style={{ fontFamily: "Inter, sans-serif" }}
          >
            Chatrooms
          </h2>
          <div className="m-2">
            <Form.Control
              type="serach"
              id="searchChat"
              placeholder="&#xf002; Search"
              onChange={(e) => {
                handleSearchChatRoom(e?.target?.value);
              }}
            />
          </div>
          <div className="chatroomContainer">
            {roomList?.length > 0
              ? roomList?.map((item) => {
                  let selectedRoomId;
                  {
                    userDetails?.roles?.label == "admin"
                      ? (selectedRoomId = item?.room_id)
                      : (selectedRoomId = item?.rooms?.room_id);
                  }
                  let data;
                  if (notificationData?.length > 0) {
                    data = notificationData?.filter(
                      (message) => message.room_id === selectedRoomId
                    );
                    let filterData = data[data?.length - 1];
                    if (filterData?.created) {
                      const parsedTime = moment(
                        filterData?.created,
                        "HH:mm:ss"
                      );

                      const userTimeZone =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;

                      filterData.created = parsedTime
                        .clone()
                        .tz(userTimeZone)
                        .format("hh:mm");
                      data = filterData;
                    }
                  }
                  const color = randomColor({
                    luminosity: "light",
                    format: "hsla",
                    alpha: 0.8,
                  });
                  return (
                    <div
                      className={`item ${
                        (item?.is_active || item?.rooms?.is_active) == true
                          ? "active"
                          : "inactive"
                      } ${
                        (item?.rooms?.id || item?.id) === roomPrimaryId
                          ? "selected"
                          : ""
                      }`}
                    >
                      <Row
                        onClick={() => {
                          handleJoinRoom(item);
                          handleRefresh(item,color);
                          handleDeleteNotification(selectedRoomId);
                        }}
                      >
                        <Col
                          sm={3}
                          className="d-flex justify-content-md-center"
                        >
                          <IoPersonCircleOutline size={55} color={color} />
                        </Col>
                        <Col sm={9}>
                          <Row>
                            <span
                              style={{
                                fontFamily: "Inter, sans-serif",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                            >
                              {item?.rooms?.room_name || item?.room_name}
                            </span>
                            {data !== undefined ? (
                              <ShowNotificationComponent
                                notificaLength={
                                  notificationData?.filter(
                                    (v) => v?.room_id === selectedRoomId
                                  )?.length
                                }
                                notificationData={data}
                              />
                            ) : (
                              ""
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              : "No any Chatroom"}
          </div>
        </Container>
      </FormContainer>
    </>
  );
};

export default ChatRooms;
const FormContainer = styled.div`
  background-color: "#ffffff";
  box-shadow: 0 4px 4px 5px #a4e2cb80;
  // margin-top: 50px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    background-color: #eeeeee;
    padding: 1rem;
    border-radius: 0.8rem;
    color: black;
    width: 100%;
    font-size: 1rem;
    &:focus {
      border: 0.1rem solid #74c9a9;
      outline: none;
    }
  }

  .notification {
    background-color: #129d16c4;
    border-radius: 50%;
    display: flex;
    width: 20%;
    color: #ffffff;
    justify-content: center;
  }
`;
